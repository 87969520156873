<template>
  <v-theme-provider light>
    <base-section class="text-center white fullheight" space="86">
      <base-section-heading title="Congratulations" color="accent"> </base-section-heading>
      <v-responsive class="d-flex align-center mx-auto mb-4" max-width="700" width="100%">
        <v-container class="text-h5 accent--text font-weight-medium pr-8">
          <v-icon size="60" color="accent">mdi-account-search</v-icon>
          <p class="pricing-header">You are searchable!</p>
          <br />
          <v-icon size="60" color="accent">mdi-map-marker</v-icon>
          <p class="pricing-header">You own your territory!</p>
          <br />
          <v-spacer /><v-btn block dark color="primary" class="v-size--x-large font-weight-bold footer" min-width="96" @click="exploreAction()">Explore ProInsight</v-btn> <v-spacer />
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  name: 'PayingFeatures',

  data: () => ({
    features: [
      {
        icon: 'mdi-account-search',
        title: 'You are searchable!',
        color: 'accent'
      },
      {
        icon: 'mdi-map-marker',
        title: 'You own your zip code!',
        color: 'accent'
      }
    ]
  }),

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    }
  },

  mounted() {
    setTimeout(this.fetchRegistrationStage, this.nullTimeoutProp)
  },

  methods: {
    exploreAction() {
      this.$router.push({
        name: 'searchroute'
      })
    },

    fetchRegistrationStage() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🌳 FeaturesSection.fetchRegistrationStage', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isPageLoading = true
          this.showGenericFailure = false
          this.genericFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.registrationStage = data.profile.registrationstage
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>
